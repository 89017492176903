<template>
  <div>
    <div id="top-bar-one" class="text-center pt-7 pb-7">
      <v-container><Logo width="160" /> </v-container>
    </div>
    <div class="login-outer text-center">
      <div class="login-inner">
        <v-icon v-if="!loginSuccess" x-large class="closed mb-10">
          fas fa-door-closed
        </v-icon>
        <v-icon v-else x-large color="primary" class="opened mb-10">
          fas fa-door-open
        </v-icon>
        <form v-on:keyup.enter="logIn">
          <v-text-field
            filled
            label="Username"
            v-model="username"
            name="username"
          ></v-text-field>
          <v-text-field
            filled
            label="Password"
            type="password"
            v-model="password"
            name="password"
          ></v-text-field>

          <v-btn @click="logIn" color="primary" block x-large depressed>
            LOG IN
          </v-btn>
          <div v-if="error" class="mt-4" style="color: red">
            Wrong username or password :(
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import Logo from "../components/Logo";
export default {
  name: "Login",
  components: {
    Logo,
  },
  data() {
    return {
      username: "",
      password: "",
      loginSuccess: false,
      error: false,
    };
  },
  created() {
    if (this.isTokenSet) this.$router.push("/");
  },
  mounted() {
    //
  },
  methods: {
    logIn() {
      this.$http
        .post("https://api.avonovasales.no/cake/api/auth/signin", {
          username: this.username,
          password: this.password,
        })
        .then((response) => {
          if (response.status === 200 && response.data.accessToken) {
            localStorage.setItem(
              "token",
              JSON.stringify(response.data.accessToken)
            );
            this.$store
              .dispatch("setTokenInState")
              .then((this.loginSuccess = true));
            setTimeout(() => {
              this.$router.push({
                name: "Home",
                params: { date: new Date().toISOString().substr(0, 10) },
              });
            }, 1500);
          }
        })
        .catch(() => (this.error = true));
    },
  },
  computed: {
    isTokenSet() {
      return this.$store.state.token;
    },
  },
  watch: {},
};
</script>
<style lang="scss">
.login-outer {
  max-width: 700px;
  margin: 60px auto 0 auto;
  background: #faf7f6;
  padding: 3%;
}

.closed {
  background: white;
  border: 1px solid lightgrey;
  width: 100px;
  height: 100px;
  border-radius: 50px;
}

.opened {
  background: white;
  border: 5px solid var(--v-primary-base);
  width: 100px;
  height: 100px;
  border-radius: 50px;
}
</style>
